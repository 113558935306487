/* Team.css */

/* Styles for the Team component */
#team {
    text-align: center;
  }
  
 
  
  .section-title {
   margin-bottom: 0px;
  }
  
  /* Styles for the CarForm component */
  #car-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  